.sidbarMenu .antd-menu-item {
  margin-bottom: 8px !important;
}
.headerclass {
  padding: 0;
  background: #f9f9fc;
  height: 83px !important;
  border-bottom: 1px solid #ddd;
  /* border:"none" */
}

.content {
  position: fixed;
  overflow-y: scroll;
  /* top: 19%; */
  top:4.3rem;
  width: 100%;
  padding-inline:3.8rem 1rem;
  padding-bottom: 5.3rem;
}
@media screen and (max-width: 1020px) {
  
  .content {
    top:4.5rem;
    padding-bottom: 5rem;
  }
}
