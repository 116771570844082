.sideIconn{
    width: 50px;
    height: 50px;
}

.icon {
    color: #a3b3b8;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px; 
    border-radius: 50%;
    width: 5px; 
    height: 5px;
    background-color: #fff; 
}

.belowicon_sec{
    margin: 10px;
    border-radius: 50%;
    padding: 10px;
    
}

.meuItem{
    background-color: #F4F7FE;
}


.first_icon{
    position: fixed;
    top: 6%;
}
.first_icon .img_show{
    border-radius: 50%;
    padding: 5px;
    background-color: #4254F9;
    cursor: pointer;
    position: relative;
    color: #4254F9;
    width: 15px;
    height: 15px;

}

.sideiconn {
    padding-right:6
}

.custom-menu{
    margin-top:10%;
    color: #C3CAD9;
    background: #F9F9FC;
    margin-left: 2;
    margin-right: 7;
    position: fixed;
    border:none;
    width: 50
  }

  .custom-menu .menuItemStyle{
    background: #ffffff;
    border-radius: 40;
    width: 36;
    height: 36;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 4px;
    padding-left: 6;

    margin-bottom: 10;
  }
  .mainsidebar
  {
    /* background: #F9F9FC;
     */
     /* position: fixed;
     left: 0;
     background: rgb(195, 202, 217) !important; */
    /* border-right: 1px solid #ddd !important; */
    overflow-y: "auto" !important;
  }

  :where(.css-dev-only-do-not-override-1b0bdye).ant-menu-vertical >.ant-menu-item{
    border-radius: 40px !important;
    width: 30 !important;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 4px; */

  }

  .menuItemStylemainmiddle{
    /* background: #ffffff; */
    /* background: #F9F9FC;
    width: 30;
    height: 30;
    padding-left: 6;
    margin-bottom: 10; */

  }

  .secondmenu{
        margin-top: 6%;
        color: #C3CAD9 !important;
        background: #F9F9FC;
        margin-left: 4;
        margin-right:7;
        position: fixed;
        /* border:none !important; */
        width: 65px;
      
  }

  
  .lastmenu
  {
    bottom: 7%;
    margin-left: 2;
    color: #C3CAD9;
    background: #F9F9FC;
    position: fixed;
    padding-right:8;
    /* border:none !important;  */
    width: 65px;
  }

  .lastmenuitem{
    background: #F9F9FC;
    /* border-radius: 40; */
    width: 30;
    height: 36;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 4px; */
    padding-left: 5;
    margin-bottom: 10;
  }
  .lastmenuitmeicon{
    padding-right:9;
    width: 44
  }


  @media screen and (min-width: 765px) and (max-width: 770px) {

    .topSideNav {
        padding: 10px;
    }
    .secondmenu{
      margin-top: 20%;
    }
  
  }


  @media screen and (min-width: 815px) and (max-width: 825px) {

    .topSideNav {
        padding: 10px;
    }
    .secondmenu{
      margin-top: 20%;
    }
  
  }


@media screen and (min-width: 1200px) {

    .topSideNav {
        padding: 10px;
    }
  
}




@media screen and (min-width: 1023px) and (max-width: 1025px) {

  .topSideNav {
      padding: 10px;
  }
  .secondmenu{
    margin-top: 16%;
  }

}

/* .sidbarMenu .antd-menu-item{
    margin-bottom: 8px !important;
} */