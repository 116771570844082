
*{
  margin: 0;
  padding: 0;
}
body{
  width: 100%;
  height: 100vh;
}

.card-body{
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  justify-content: space-between !important;
  /* padding: 15px !important; */
  background: #ffffff !important;
  height: 190px !important;
  border-radius: 15px !important;
  width: 99%;
  margin: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 5px;
  margin-top: 10px;
}



.ant-table-wrapper .css-dev-only-do-not-override-1b0bdye
{
  background: #F9F9FC !important;
}

.ant-table-wrapper :hover .css-dev-only-do-not-override-1b0bdye :hover .ant-table-row .ant-table-row-level-0 :hover {
  background-color:  #F9F9FC !important;
}


/*  table  cell size change with this*/
.ant-table-cell{
  background: #F9F9FC !important;
  /* height: 4px !important; */

}
.ant-table-thead .ant-table-header{
  background: #F9F9FC !important;
 
}
/* style="table-layout: fixed;" */

.card{
  
    height: 46% !important;
    padding: 8px !important;
    border-radius: 30px !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
    background-color: white !important;
    display: flex !important;
    justify-content: left !important;
    align-items: center !important;
    color: #a3b3b8 !important;
    gap: 15px;

}

.headerCardRow{
  /* height: 100% !important; */
}

.headericon{
  display: inline-block;
  width: 19px;
  height: 19px;


  text-align: center;
  line-height: 12px; /* Center the content vertically */
  font-weight: 500;
}


.ant-layout-sider-trigger{

    background-color:"#F9F9FC"
  
}

.ant-dropdown-menu {
  padding: 10px; /* Adjust the padding as needed */
}

::-webkit-scrollbar{
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-thumb{
  background-color:rgb(201, 195, 195)
}
/* table size */
.ant-table {
  font-size: 9px !important; /* Change this value to your desired font size */
}

.ant-table th,
.ant-table td {
  font-size: 12px !important; 
  padding: 1px !important;
  /* Change this value to your desired font size */
}

.ant-select-selector{
  background: #8383ac;
  border: none;
}
.selectTag .ant-select-selector{
  background-color: #f1f5f9 !important;
  border-color: #dbeafe !important;
}
.config-select .ant-select-selector{
  background-color: #f1f5f9 !important;
  border-color: #dbeafe !important;
}

.custom-select .ant-select-selector{
  border-radius: 30px; 
  background: #ffffff;
  color: #4285F4;
  font-size: 12px
}


.tdate-select .ant-select-selector{
  color: #4285F4 !important;
  border-radius: 20px !important;
  /* padding: 0 8px; */

}

.tdate-select .ant-select-selection-placeholder ,.tdate-select .ant-select-arrow{
  color: #4285F4 !important;
}

.menuItemStyle
{
    background: #ffffff;   
  border-radius: 40;
  height: 36;
  width: 36;
  border-radius: 40;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 4px ; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 5px;
  margin-bottom: 10;


  
}




input[type="checkbox"] {
  margin-inline: 5px;
  cursor: pointer;
}

td {
  /* padding: 0 5px; */
  border: 1px solid #ddd;
  border-top: none;
  text-align: left;
  font-size: 13px;
  color:  #646666;
}


.react-flow__edge{
  color: #4285f4 !important;
}